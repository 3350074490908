<template>
  <div></div>
</template>

<script>
export default {
    created(){
        let path = `${this.$route.query.path}?id=${+this.$route.query.id}`;
        this.$router.push(path);
    }
}
</script>

<style>

</style>