<template>
  <div></div>
</template>

<script>
export default {
    created(){
        let path = this.$route.query.path;
        let query = this.$route.query;
        this.$router.push({path,query});
    }
}
</script>

<style>

</style>